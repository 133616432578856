.block
  +block(var(--block-margin-bottom, 60px))
  &.is-medium
    --block-margin-bottom: 80px
  &.is-normal
    --block-margin-bottom: 60px
  &.is-xxsmall
    --block-margin-bottom: 20px
  &.is-xsmall
    --block-margin-bottom: 30px
  &.is-small
    --block-margin-bottom: 40px
    +mobile
      --block-margin-bottom: 20px
  &.is-small-mobile
    +mobile
      --block-margin-bottom: 40px
  &.is-middle
    --block-margin-bottom: 120px
    +mobile
      --block-margin-bottom: 80px
