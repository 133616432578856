$section-padding: 3rem 1.5rem !default
$section-padding-small: 9rem 1.5rem !default
$section-padding-medium: 9rem 1.5rem !default
$section-padding-large: 18rem 1.5rem !default

.section
  padding: var(--section-padding-top,0px) 0 var(--section-padding-bottom,100px)
  // background-color: var(--section-background-color,#{$background-01})
  overflow: hidden
  opacity: 0
  // &.is-small
  //   +mq-all
  //     --section-padding-top: 60px
  //     --section-padding-bottom: 60px
  // &.is-medium
  //   +mq-all
  //     --section-padding-top: 80px
  //     --section-padding-bottom: 80px

// body.is-section-reverse .section:not(.footer):nth-child(2n),
// body:not(.is-section-reverse) .section:not(.footer)
//   --section-background-color:  #{$background-02}

// body.is-section-reverse .section:not(.footer):nth-child(2n-1),
// body:not(.is-section-reverse) .section:not(.footer):nth-child(2n)
//   --section-background-color: #{$background-01}