=typography-size($target:'')
  @each $size in $sizes
    $i: index($sizes, $size)
    .is-size-#{$i}#{if($target == '', '', '-' + $target)}
      font-size: $size !important
  @each $size in $sizes-custom
    .font-size-#{$size}#{if($target == '', '', '-' + $target)}
      +mq-all
        font-size: #{$size}px !important

+typography-size()

$alignments: ('centered': 'center', 'justified': 'justify', 'left': 'left', 'right': 'right')

@each $alignment, $text-align in $alignments
  .has-text-#{$alignment}
    +mq-all
      text-align: #{$text-align} !important

.is-capitalized
  text-transform: capitalize !important

.is-lowercase
  text-transform: lowercase !important

.is-uppercase
  text-transform: uppercase !important

.is-italic
  font-style: italic !important

.has-text-weight-500
  font-weight: 500 !important
.has-text-weight-light
  font-weight: $weight-light !important
.has-text-weight-normal
  font-weight: $weight-normal !important
.has-text-weight-medium
  font-weight: $weight-medium !important
.has-text-weight-semibold
  font-weight: $weight-semibold !important
.has-text-weight-bold
  font-weight: $weight-bold !important

.is-family-primary
  font-family: $family-primary !important

.is-family-secondary
  font-family: $family-secondary !important

.is-family-third
  font-family: $family-third !important

.is-family-sans-serif
  font-family: $family-sans-serif !important

.is-family-monospace
  font-family: $family-monospace !important

.is-family-code
  font-family: $family-code !important
