.movie
  display: flex
  justify-content: space-between
  list-style: none
  iframe,
  video
    background: rgb(255, 255, 255, 0)
    width: 100%
    height: 100%
    aspect-ratio: 293 / 521
    object-fit: cover
  li
    position: relative
    padding: 0
    &.noplay svg
      display: none
    svg
      content: ""
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)

    @media screen and (max-width: 928px)
      &.noplay svg
        display: none

    @-moz-document url-prefix()
      &.noplay svg
        display: none