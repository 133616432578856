$title-color: $text-strong !default
$title-family: false !default
$title-size: $size-3 !default
$title-weight: $weight-semibold !default
$title-line-height: 1.125 !default
$title-strong-color: inherit !default
$title-strong-weight: inherit !default
$title-sub-size: 0.75em !default
$title-sup-size: 0.75em !default

$subtitle-color: $text !default
$subtitle-family: false !default
$subtitle-size: $size-5 !default
$subtitle-weight: $weight-normal !default
$subtitle-line-height: 1.25 !default
$subtitle-strong-color: $text-strong !default
$subtitle-strong-weight: $weight-semibold !default
$subtitle-negative-margin: -1.25rem !default

.title,
.subtitle
  word-break: break-word
  em,
  span
    font-weight: inherit
  sub
    font-size: $title-sub-size
  sup
    font-size: $title-sup-size

%title_is-type-03
  --title-font-weight: 300
  --title-font-size: 16px
  --title-line-height: 1.5
  --title-letter-spacing: 0.8px
  --title-margin-bottom: 10px

.title
  font-weight: var( --title-font-weight,500)
  font-stretch: normal
  font-style: normal
  font-family: var(--title-font-family,#{$family-secondary})
  line-height: var( --title-line-height,1.2)
  letter-spacing: var( --title-letter-spacing,0.1em)
  color: var( --title-color,#{$text})
  font-size: var( --title-font-size,18px)
  +block(var(--title-margin-bottom,20px))
  &.is-family-secondary
    --title-font-weight: 500
  &.is-indent
    margin-left: -0.5em
  &.is-type-01
    --title-font-family: #{$family-secondary}
    +tablet
      --title-font-size: 32px
      --title-letter-spacing: 1.6px
  &.is-type-02
    --title-font-size: 16px
    --title-margin-bottom: 20px
    --title-color: #{$primary}
    --title-font-family: #{$family-third}
  &.is-type-03
    @extend %title_is-type-03
  &.is-type-03-02
    @extend %title_is-type-03
    +tablet
      --title-font-size: 24px
      --title-line-height: 1.5
      --title-letter-spacing: 1.2px
      --title-margin-bottom: 10px
  &.is-type-04
    --title-font-size: 32px
    --title-line-height: 1.5
  &.is-type-05
    --title-font-size: 18px
  &.is-pagetitle
    --title-font-family: #{$family-secondary}
    --title-font-weight: 500
    --title-font-size: 20px
    --title-line-height: 1.5
    --title-letter-spacing: 1px
    --title-margin-bottom: 20px
    margin-top: 40px
    +mobile
      text-align: left
    +tablet
      --title-font-size: 32px
      --title-line-height: 1.5
      --title-letter-spacing: 1.6px
      --title-margin-bottom: 40px
      margin-top: 100px

.title-group
  +block(var(--title-margin-bottom,40px))
  h2.title
    --title-margin-bottom: 10px
  h3.title
    --title-font-size: 24px
    --title-line-height: 1.45

.shop-logo
  img
    height: 60px
    margin-bottom: 40px

.shop-info
  margin-top: 80px