$box-color: $text !default
$box-background-color: $scheme-main !default
$box-radius: $radius-large !default
$box-shadow: 0 0.5em 1em -0.125em rgba($scheme-invert, 0.1), 0 0px 0 1px rgba($scheme-invert, 0.02) !default
$box-padding: 1.25rem !default

$box-link-hover-shadow: 0 0.5em 1em -0.125em rgba($scheme-invert, 0.1), 0 0 0 1px $link !default
$box-link-active-shadow: inset 0 1px 2px rgba($scheme-invert, 0.2), 0 0 0 1px $link !default

.box
  background-color: var(--box-background-color,#{$box-background-color})
  border-radius: var(--box-border-radius,#{$box-radius})
  display: block
  padding: var(--box-padding,#{$box-padding})