@import "../utilities/mixins"

$column-gap: 0.75rem !default

:root
  --column-gap: 10px

.columns
  display: flex
  flex-wrap: wrap
  +ltr-property("margin", calc(-1 * (var(--column-gap) / 2)), false)
  +ltr-property("margin", calc(-1 * (var(--column-gap) / 2)))
  margin-top: calc(-1 * var(--column-gap-row,var(--column-gap)))
  > *
    padding-left: calc( var(--column-gap) / 2 )
    padding-right: calc( var(--column-gap) / 2 )
    margin-top: var(--column-gap-row,var(--column-gap))
    flex: var(--column-flex-grow,1) var(--column-flex-shrink,1) var(--column-flex-basis,0)
    max-width: var(--column-flex-basis,initial)
    &.is-narrow
      flex: none
      width: unset
  // &.is-column-tablet
  //   > *
  //     +tablet
  //       --column-flex-basis: var(--column-flex-basis-tablet,100%)
  &.is-nowrap
    flex-wrap: nowrap
  &.is-column-mobile
    +mobile
      flex-direction: column
      --column-flex-basis: 100%
  // @for $i from 0 through 12
  //   &.is-#{$i}
  //     +mq-all
  //       &>*
  //         --column-flex-basis: #{percentage(divide($i, 12))}
  //         --column-flex-grow:0
  //   &.is-gap-#{$i}
  //     +mq-all
  //       --column-gap: #{$column-gap * $i}
  //   &.is-gap-row-#{$i}
  //     +mq-all
  //       --column-gap-row: #{$column-gap * $i}
