$content-heading-color: $text-strong !default
$content-heading-weight: $weight-semibold !default
$content-heading-line-height: 1.125 !default

$content-blockquote-background-color: $background !default
$content-blockquote-border-left: 5px solid $border !default
$content-blockquote-padding: 1.25em 1.5em !default

$content-pre-padding: 1.25em 1.5em !default

$content-table-cell-border: 1px solid $border !default
$content-table-cell-border-width: 0 0 1px !default
$content-table-cell-padding: 0.5em 0.75em !default
$content-table-cell-heading-color: $text-strong !default
$content-table-head-cell-border-width: 0 0 2px !default
$content-table-head-cell-color: $text-strong !default
$content-table-foot-cell-border-width: 2px 0 0 !default
$content-table-foot-cell-color: $text-strong !default

.content
  text-align: left
  // Inline
  li + li
    margin-top: 1.5em
  // Block
  p
    line-height: 2
    &:not(:last-child)
      margin-bottom: 1.8em
  a
    text-decoration: underline
    word-break: break-word
  h1,
  h2,
  h3,
  h4,
  h5,
  h6
    //color: $content-heading-color
    font-weight: 500
    line-height: 1.5
    font-family: $family-secondary
  h1
    font-size: 2em
    margin-bottom: 0.5em
    &:not(:first-child)
      margin-top: 1em
  h2
    font-size: 18px
    margin-bottom: 20px
    &:not(:first-child)
      margin-top: 40px
  h3
    font-size: 16px
    margin-bottom: 20px
    &:not(:first-child)
      margin-top: 40px
  h4
    font-size: 14px
    margin-bottom: 20px
    &:not(:first-child)
      margin-top: 40px
  h5
    font-size: 1.125em
    margin-bottom: 0.2em
  h6
    font-size: 1em
    margin-bottom: 1em
  blockquote
    background-color: $content-blockquote-background-color
    +ltr-property("border", $content-blockquote-border-left, false)
    padding: $content-blockquote-padding
  ol
    list-style-position: outside
    +ltr-property("margin", 2em, false)
    margin-top: 1em
    &:not([type])
      list-style-type: decimal
      &.is-lower-alpha
        list-style-type: lower-alpha
      &.is-lower-roman
        list-style-type: lower-roman
      &.is-upper-alpha
        list-style-type: upper-alpha
      &.is-upper-roman
        list-style-type: upper-roman
    &.is-bracket,
    &.is-bracket-closing
      list-style-type: none
      li
        counter-increment: cnt
        position: relative
        &::before
          text-align: right
          position: absolute
          top: 0
          left: -2.3em
          width: 2em
          display: inline-block
    &.is-bracket
      li
        &::before
          content: "(" counter(cnt) ")"
    &.is-bracket-closing
      li
        &::before
          content: counter(cnt) ")"
  ul
    list-style: disc outside
    +ltr-property("margin", 2em, false)
    ul
      list-style-type: circle
      margin-top: 0.5em
      ul
        list-style-type: square
  li
    ol,
    ul
      margin-top: 0.3em
  ol,ul,dl
    text-align: left
  dd
    +ltr-property("margin", 2em, false)
  figure
    text-align: center
    img
      display: inline-block
  figcaption
    margin-top: 0.5em
  pre
    +overflow-touch
    overflow-x: auto
    padding: $content-pre-padding
    white-space: pre
    word-wrap: normal
  sup,
  sub
    font-size: 75%
  table
    width: 100%
    font-family: $family-secondary
    background: #F3F2F2
    th,td
      border: 1px solid $white
      padding: 4px
      line-height: 1.5
      vertical-align: middle
    th
      text-align: center
      font-weight: 500
      background: #C9B59D
      font-size: 16px
      &.is-secondary
        background: #D2C6B7
    thead
      th
        background: #C9B59D
    &.is-price
      table-layout: fixed
      tr
        th:first-child:not(:last-child)
          width: 135px
          white-space: nowrap
      td
        text-align: right

