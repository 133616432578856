// .is-radiusless
//   border-radius: 0 !important

// .is-shadowless
//   box-shadow: none !important

// .is-clickable
//   cursor: pointer !important

// .is-unselectable
//   @extend %unselectable

// .is-width-auto
//   width: auto !important

// .is-filter-invert
//   filter: invert(1) !important

.is-grayscale
  filter: grayscale(1)

.is-offset-0-mobile
  +mobile
    margin-right: calc( -1 * $container-offset ) !important
    margin-left: calc( -1 * $container-offset ) !important

.is-offset-0-right-mobile
  +mobile
    margin-right: calc( -1 * $container-offset ) !important