$container-offset: (2 * $gap) !default
$container-max-width: $fullhd !default

.container
  flex-grow: 1
  margin: 0 auto
  position: relative
  width: auto
  padding-left: var(--container-offset,#{$container-offset})
  padding-right: var(--container-offset,#{$container-offset})
  max-width: calc(var(--container-width,1050px) + (var(--container-offset,#{$container-offset})*2))
  & &
    --container-offset: 0px
  &.is-auto
    +mq-all
      --container-width: 0
      text-align: left
      display: inline-flex
      flex-direction: column
  &.is-full
    max-width: none
  &.is-xsmall
    --container-width: 480px
  &.is-xxsmall
    --container-width: 600px
  &.is-small
    --container-width: 700px
  &.is-medium
    --container-width: 800px
  &.is-xmedium
    --container-width: 860px
  &.is-large
    --container-width: 980px
  &.is-xlarge
    --container-width: 1050px
  &.is-xxlarge
    --container-width: 1100px
