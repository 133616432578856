@import "initial-variables"

=clearfix
  &::after
    clear: both
    content: " "
    display: table

=center($width, $height: 0)
  position: absolute
  @if $height != 0
    left: calc(50% - (#{$width} / 2))
    top: calc(50% - (#{$height} / 2))
  @else
    left: calc(50% - (#{$width} / 2))
    top: calc(50% - (#{$width} / 2))

=fa($size, $dimensions)
  display: inline-block
  font-size: $size
  height: $dimensions
  line-height: $dimensions
  text-align: center
  vertical-align: top
  width: $dimensions

=hamburger($dimensions)
  cursor: pointer
  display: block
  height: $dimensions
  position: relative
  width: $dimensions
  span
    background-color: currentColor
    display: block
    height: 2px
    left: calc(50% - 17px)
    position: absolute
    transform-origin: center
    transition-duration: $speed
    transition-property: background-color, opacity, transform
    transition-timing-function: $easing
    width: 40px
    &:nth-child(1)
      top: calc(50% - 9px)
    &:nth-child(2)
      top: calc(50% + 7px)
    &:nth-child(3)
      top: calc(50% + 7px)
  // &:hover
  //   background-color: bulmaRgba(black, 0.05)
  // Modifers
  &.is-active
    span
      width: 40px
      left: calc(50% - 12px)
      &:nth-child(1)
        transform: translateY(7px) rotate(45deg)
      &:nth-child(2)
        transform: translateY(-9px) rotate(-45deg)
      &:nth-child(3)
        transform: translateY(-9px) rotate(-45deg)

=overflow-touch
  -webkit-overflow-scrolling: touch

=placeholder
  $placeholders: ':-moz' ':-webkit-input' '-moz' '-ms-input'
  @each $placeholder in $placeholders
    &:#{$placeholder}-placeholder
      @content

// Responsiveness

=from($device)
  @media screen and (min-width: $device)
    @content

=until($device)
  @media screen and (max-width: $device - 1px)
    @content

=mobile
  @media screen and (max-width: $tablet - 1px)
    @content

=tablet
  @media screen and (min-width: $tablet), print
    @content

=tablet-only
  @media screen and (min-width: $tablet) and (max-width: $desktop - 1px)
    @content

=touch
  @media screen and (max-width: $desktop - 1px)
    @content

=desktop
  @media screen and (min-width: $desktop)
    @content

=desktop-only
  @if $widescreen-enabled
    @media screen and (min-width: $desktop) and (max-width: $widescreen - 1px)
      @content

=until-widescreen
  @if $widescreen-enabled
    @media screen and (max-width: $widescreen - 1px)
      @content

=widescreen
  @if $widescreen-enabled
    @media screen and (min-width: $widescreen)
      @content

=widescreen-only
  @if $widescreen-enabled and $fullhd-enabled
    @media screen and (min-width: $widescreen) and (max-width: $fullhd - 1px)
      @content

=until-fullhd
  @if $fullhd-enabled
    @media screen and (max-width: $fullhd - 1px)
      @content

=fullhd
  @if $fullhd-enabled
    @media screen and (min-width: $fullhd)
      @content

=ltr
  @if not $rtl
    @content

=rtl
  @if $rtl
    @content

=ltr-property($property, $spacing, $right: true)
  $normal: if($right, "right", "left")
  $opposite: if($right, "left", "right")
  @if $rtl
    #{$property}-#{$opposite}: $spacing
  @else
    #{$property}-#{$normal}: $spacing

=ltr-position($spacing, $right: true)
  $normal: if($right, "right", "left")
  $opposite: if($right, "left", "right")
  @if $rtl
    #{$opposite}: $spacing
  @else
    #{$normal}: $spacing

// Placeholders

=unselectable
  -webkit-touch-callout: none
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

%unselectable
  +unselectable

=arrow($color: transparent)
  border: 2px solid $color
  border-radius: 2px
  border-right: 0
  border-top: 0
  content: " "
  display: block
  height: 0.625em
  margin-top: -0.4375em
  pointer-events: none
  position: absolute
  top: 50%
  transform: rotate(-45deg)
  transform-origin: center
  width: 0.625em

%arrow
  +arrow

=block($spacing: $block-spacing , $tablet-spacing: false)
  &:not(:last-child)
    margin-bottom: $spacing
    @if $tablet-spacing
      +tablet
        --block-margin-bottom: $tablet-spacing
%block
  +block

=delete
  @extend %unselectable
  -moz-appearance: none
  -webkit-appearance: none
  //background-color: bulmaRgba($scheme-invert, 0.2)
  border: none
  border-radius: $radius-rounded
  cursor: pointer
  pointer-events: auto
  display: inline-block
  flex-grow: 0
  flex-shrink: 0
  font-size: 0
  height: 20px
  max-height: 20px
  max-width: 20px
  min-height: 20px
  min-width: 20px
  outline: none
  position: absolute
  vertical-align: top
  width: 20px
  &::before,
  &::after
    background-color: $primary
    content: ""
    display: block
    left: 50%
    position: absolute
    top: 50%
    transform: translateX(-50%) translateY(-50%) rotate(45deg)
    transform-origin: center center
  &::before
    height: 1px
    width: 50%
  &::after
    height: 50%
    width: 1px
  // &:hover,
  // &:focus
  //   background-color: bulmaRgba($scheme-invert, 0.3)
  // &:active
  //   background-color: bulmaRgba($scheme-invert, 0.4)
  // Sizes
  &.is-small
    height: 16px
    max-height: 16px
    max-width: 16px
    min-height: 16px
    min-width: 16px
    width: 16px
  &.is-medium
    height: 24px
    max-height: 24px
    max-width: 24px
    min-height: 24px
    min-width: 24px
    width: 24px
  &.is-large
    height: 80px
    max-height: 80px
    max-width: 80px
    min-height: 80px
    min-width: 80px
    width: 80px

%delete
  +delete

=loader
  animation: spinAround 500ms infinite linear
  border: 2px solid $grey-lighter
  border-radius: $radius-rounded
  border-right-color: transparent
  border-top-color: transparent
  content: ""
  display: block
  height: 1em
  position: relative
  width: 1em

%loader
  +loader

=overlay($offset: 0)
  bottom: $offset
  left: $offset
  position: fixed
  right: $offset
  top: $offset

%overlay
  +overlay

// original
=letter-spacing($space: 0)
  letter-spacing: #{$space}
  padding-left: #{$space}

=ellipsis($line: 1, $lineHeight: 1.75)
  overflow: hidden
  text-overflow: ellipsis
  display: -webkit-box
  -webkit-box-orient: vertical
  -webkit-line-clamp: $line
  @media all and (-ms-high-contrast:none)// for IE11
    max-height: calc(1em * (#{$line} * #{$lineHeight}))

=mq-all
  @content
  &-mobile
    +mobile
      @content
  &-tablet
    +tablet
      @content
  &-desktop
    +desktop
      @content
