@keyframes spinAround
  from
    transform: rotate(0deg)
  to
    transform: rotate(359deg)

// original
@keyframes fade
  from
    opacity: 0
  to
    opacity: 1
@keyframes translateX
  from
    transform: translateX(0)
  to
    transform: translateX(100%)

.is-opacity-hidden
  opacity: 0

.is-opacity-show
  opacity: 1
  transition: all 0.5s linear
