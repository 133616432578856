$footer-background-color: $scheme-main-bis !default
$footer-color: false !default
$footer-padding: 3rem 1.5rem 6rem !default

.footer-01
  --link-color: #{$white}
  background: #24262B
  color: $white
  padding: 80px 0

.footer-02
  --link-color: #24262B
  background: #fff
  color: #24262B
  padding: 15px 0 100px 0

.footer-sitelogo
  svg
    width: 160px
    height: 93px

.footer-sns
  font-size: 30px
  line-height: 1
  ul
    display: inline-flex
    flex-wrap: wrap
    justify-content: center
    gap: 40px
  a
    display: inline-block

.footer-copyrights
  font-size: 10px
  @extend .is-family-secondary
  +tablet
    font-size: 12px
