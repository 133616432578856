

$displays: 'block' 'flex' 'inline' 'inline-block' 'inline-flex'

@each $display in $displays
  .is-#{$display}
    +mq-all
      display: #{$display} !important

.is-hidden
  +mq-all
    display: none !important

.is-sr-only
  border: none !important
  clip: rect(0, 0, 0, 0) !important
  height: 0.01em !important
  overflow: hidden !important
  padding: 0 !important
  position: absolute !important
  white-space: nowrap !important
  width: 0.01em !important
