$dimensions: 16 24 32 48 64 96 128 !default

%imageRatio
  img,
  .has-ratio
    @extend %overlay
    height: 100%
    width: 100%

.image
  display: block
  position: relative
  img
    display: block
    height: auto
    width: 100%
    &.is-rounded
      border-radius: $radius-rounded
  &.is-fullwidth
    width: 100%
  &.is-cover
    img
      object-fit: cover
      width: 100%
      height: 100%
  // &.has-shadow
  //   $radius: 8px
  //   border-radius: #{$radius}
  //   position: relative
  //   &::before
  //     content: ""
  //     display: block
  //     position: absolute
  //     top: 0
  //     left: 0
  //     right: 0
  //     bottom: 0
  //     box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16)
  //     border: 1px solid #fff
  //     border-radius: #{$radius}
  //     z-index: 1
  //   img
  //     border-radius: #{$radius}
  //   .badge
  //     position: absolute
  //     bottom: -10px
  //     right: -7px
  //     z-index: 2
  // Ratio
  // &.is-square,
  // &.is-1by1,
  // &.is-5by4,
  // &.is-4by3,
  // &.is-3by2
  // &.is-5by3,
  &.is-16by9,
  // &.is-2by1,
  // &.is-3by1,
  // &.is-4by5,
  // &.is-3by4,
  // &.is-2by3,
  // &.is-3by5,
  // &.is-9by16,
  // &.is-1by2,
  // &.is-1by3
  &.is-gmap
    @extend %imageRatio
  // &.is-square,
  // &.is-1by1
  //   padding-top: 100%
  // &.is-5by4
  //   padding-top: 80%
  // &.is-4by3
  //   padding-top: 75%
  // &.is-3by2
  //   padding-top: 66.6666%
  // &.is-5by3
  //   padding-top: 60%
  &.is-16by9
    padding-top: 56.25%
  // &.is-2by1
  //   padding-top: 50%
  // &.is-3by1
  //   padding-top: 33.3333%
  // &.is-4by5
  //   padding-top: 125%
  // &.is-3by4
  //   padding-top: 133.3333%
  // &.is-2by3
  //   padding-top: 150%
  // &.is-3by5
  //   padding-top: 166.6666%
  // &.is-9by16
  //   padding-top: 177.7777%
  // &.is-1by2
  //   padding-top: 200%
  // &.is-1by3
  //   padding-top: 300%
  &.is-gmap
    padding-top: 62.5%

  // Sizes
  // @each $dimension in $dimensions
  //   &.is-#{$dimension}x#{$dimension}
  //     height: $dimension * 1px
  //     width: $dimension * 1px

.images-with-text
  text-align: left
  display: flex
  text-align: left
  gap: var(--images-with-text-gap,40px)
  flex-direction: column
  .images-with-text-item
    &>.images-with-text-item-figure
      +mobile
        margin-bottom: 40px
      figure
        +block(10px)
    +tablet
      display: grid
      grid-auto-columns: 1fr
      grid-auto-rows: 1fr
      grid-template-columns: var(--images-with-text-item-grid-template-columns,#{per($size:540px,$viewport:1050px)} 1fr)
      grid-template-rows: auto auto
      gap: var(--images-with-text-item-gap,0 40px)
      grid-template-areas:"img title" "img text"
      &>.images-with-text-item-figure
        align-self: center
        grid-area: img
      &>h3,
      &>.title-group
        align-self: end
        grid-area: title
      &>div,
      &>.images-with-text-item-text
        grid-area: text
      &:nth-child(2n)
        grid-template-areas:"title img" "text img"
  &.is-type-02
    .images-with-text-item
      +tablet
        --images-with-text-item-grid-template-columns: #{per($size:478px,$viewport:1050px)}
        --images-with-text-item-gap: 30px
        grid-template-areas:"img text" "img text"
      &>.images-with-text-item-text
        align-self: center

.image-zoom
  overflow: hidden
  display: block
  img
    transition: all linear 1s
  &:hover
    img
      transform: scale(1.1)