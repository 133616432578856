@font-face
  font-family: "icon-font"
  src: url('../fonts/icon-font.eot')
  src: url('../fonts/icon-font.eot?#iefix') format('eot'), url('../fonts/icon-font.woff') format('woff'), url('../fonts/icon-font.ttf') format('truetype')

@mixin icon-font-styles
  font-family: "icon-font"
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-style: normal
  font-variant: normal
  font-weight: normal
  text-decoration: none
  text-transform: none

%icon-font
  +icon-font-styles

@function icon-font-char($filename)
  $char: ""

  @if $filename == instagram
    $char: "\E001"
    
  @if $filename == line
    $char: "\E002"
    
  @if $filename == mail
    $char: "\E003"
    
  @if $filename == window
    $char: "\E004"
    
  @return $char

@mixin icon-font($filename, $insert: before, $extend: true)
  &:#{$insert}
    content: icon-font-char($filename)
    @if $extend
      @extend %icon-font
    @else
      +icon-font-styles

.icon-font-instagram
    +icon-font(instagram)
.icon-font-line
    +icon-font(line)
.icon-font-mail
    +icon-font(mail)
.icon-font-window
    +icon-font(window)
