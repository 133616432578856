
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;500&family=Zen+Kaku+Gothic+New:wght@400;500&display=swap')
@font-face
    font-family: 'Larken'
    src: url('../fonts/Larken-Medium.woff2') format('woff2'),url('../fonts/Larken-Medium.woff') format('woff'),url('../fonts/Larken-Medium.ttf') format('truetype')
    font-weight: 500
    font-style: normal
    font-display: swap
@font-face
    font-family: 'Larken'
    src: url('../fonts/Larken-Regular.woff2') format('woff2'),url('../fonts/Larken-Regular.woff') format('woff'),url('../fonts/Larken-Regular.ttf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap
