$button-color: $text-strong !default
$button-background-color: $scheme-main !default
$button-family: false !default

$button-border-color: $border !default
$button-border-width: $control-border-width !default

$button-padding-vertical: calc(0.5em - #{$button-border-width}) !default
$button-padding-horizontal: 1em !default

$button-hover-color: $link-hover !default
$button-hover-border-color: $link-hover-border !default

$button-focus-color: $link-focus !default
$button-focus-border-color: $link-focus-border !default
$button-focus-box-shadow-size: 0 0 0 0.125em !default
$button-focus-box-shadow-color: bulmaRgba($link, 0.25) !default

$button-active-color: $link-active !default
$button-active-border-color: $link-active-border !default

$button-text-color: $text !default
$button-text-decoration: underline !default
$button-text-hover-background-color: $background !default
$button-text-hover-color: $text-strong !default

$button-disabled-background-color: $scheme-main !default
$button-disabled-border-color: $border !default
$button-disabled-shadow: none !default
$button-disabled-opacity: 0.5 !default

$button-static-color: $text-light !default
$button-static-background-color: $scheme-main-ter !default
$button-static-border-color: $border !default

$button-colors: $colors !default

// The button sizes use mixins so they can be used at different breakpoints
=button-small
  border-radius: $radius-small
  font-size: $size-small
=button-normal
  font-size: $size-normal
=button-medium
  font-size: $size-medium
=button-large
  font-size: $size-large

.button
  @extend %unselectable
  background-color: var(--button-background-color,transparent)
  font-weight: var(--button-font-weight,500)
  font-size: var(--button-font-size,14px)
  color: var(--button-color,#{$primary})
  cursor: pointer
  width: var(--button-width,194px)
  max-width: 100%
  height: auto
  min-height: var(--button-min-height,48px)
  justify-content: center
  position: relative
  border-style: var(--button-border-style,solid)
  border-color: var(--button-border-color,#{$primary})
  border-width: var(--button-border-width,1px)
  border-radius: var(--button-border-radius,24px)
  letter-spacing: var(--button-letter-spacing,0.8px)
  line-height: var(--button-line-height,1.2)
  text-align: center
  text-decoration: none !important
  display: inline-flex
  justify-content: center
  align-items: center
  font-family: $family-third
  &,
  .icon-arrow
    transition: linear all 0.1s
  &:hover
    background-color: var(--button-hover-background-color,#{$white})
    color: var(--button-hover-color,#{$primary})
  .icon-font
    font-size: 14px
    position: absolute
    top: 50%
    transform: translateY(-50%)
    &:last-child
      font-size: 14px
      position: absolute
      top: 50%
      transform: translateY(-50%)
      right: 30px
  &>.icon-arrow:last-child
    width: 15px
    height: 28px
    display: block
    position: absolute
    top: 50%
    right: 22px
    transform: translateY(-50%)
    &::before,
    &::after
      content: ""
      display: block
      position: absolute
      right: 0
      transition: all linear 0.05s
    &::before
      --icon-arrow-size: 9px
      --icon-arrow-top: calc(50% - 4px)
      width: var(--icon-arrow-size)
      height: var(--icon-arrow-size)
      border: 1px solid currentColor
      border-width: 1px 1px 0 0
      top: var(--icon-arrow-top)
      transform: rotate(45deg)
      right: 1px
    &::after
      background: currentColor
      height: 1px
      width: 100%
      top: 50%
  &:hover>.icon-arrow:last-child
    width: 20px
    right: 17px
  &.is-wide
    --button-width: 300px
  &.is-fullwidth
    --button-width: 100%
  &.is-primary
    --button-background-color: #{$primary}
    --button-color: #{$white}
    --button-hover-background-color: #{$white}
    --button-hover-color: #{$primary}
  &.is-secondary
    --button-border-color: #D50783
    --button-background-color: #D50783
    --button-color: #{$white}
    --button-hover-background-color: #D50783
    --button-hover-color: #{$white}
    &:hover
      opacity: 0.8
      border-color: #D50783
  &.is-salon
    --button-hover-background-color: #D50783
    --button-hover-color: #{$white}
    &:hover
      border-color: #D50783
  &.is-circle
    --button-background-color: #{$white}
    --button-border-width: 0
    --button-color: #{$primary}
    --button-min-height:60px
    --button-width:60px
    --button-border-radius: 60px
    --button-hover-background-color: #{$primary}
    --button-hover-color: #{$white}
    &::after
      display: none

.buttons
  &:not(:first-child)
    margin-top: 60px

.button-pyon
  position: fixed
  bottom: 20px
  width: 100%
  padding: 0 40px
  z-index: 90
  text-align: center
  a
    display: inline-block
    width: 333px
    height: auto
    max-width: 100%
    &>span
      transition: all linear 0.1s
      &:hover
        opacity: 0.8