.inview
  &,
  &>*
    opacity: 0
    transition: all ease 1.0s
    transition-delay: 0.05s
  &>*
    transition-delay: 0.3s
  &:not(.inview-fade)>*
    transform: translateY(40px)
  &.is-inview
    &,
    &>*
      opacity: 1
      transform: translateY(0px)

figure.side-message
  opacity: 0
  top: 30px
  &,
  &>*
    transition: all ease 2s
    transition-delay: 0.8s
  &.is-inview
    top: 0
    z-index: 0

.button-pyon.inview-fade
  &>*
    transition-duration: 1.4s
    transition-delay: 1s