// loading
#loading
  position: fixed
  z-index: 1000000
  top: 0
  right: 0
  left: 0
  bottom: 0
  overflow: hidden
  background: #eae5e1
  color: $primary
  figure
    position: absolute
    top: 50%
    left: 50%
    transform: translateY(-50%) translateX(-50%)
    &::before
      content: ""
      display: block
      width: 100%
      height: 100%
      background: #eae5e1
      position: absolute
    svg
      width: 150px
      height: auto
  &.is-slide
    figure
      &::before
        animation: translateX forwards 0.5s
        animation-timing-function: ease
        animation-delay: 0.5s
  &.fadeout-loader
    transition-property: opacity
    transition-delay: 0.5s
    transition-duration: 0.6s
    opacity: 0
    pointer-events: none

// body:not(.home) #loading
//   display: none !important
//   pointer-events: none !important

// main
.top-main-swiper
  text-align: left
  height: var(--top-main-swiper-height,140vw)
  min-height: 524px
  max-height: 80vh
  +tablet
    --top-main-swiper-height: 768px
    --top-main-swiper-padding-x: clamp(20px,6vw,#{vw(133px,$widescreen)})
    --top-main-swiper-padding-bottom: 125px
    --top-main-swiper-hd-font-size: 60px
    --top-main-swiper-hd-line-height: 1.13
    --top-main-swiper-hd-letter-spacing: 1.6px
    --top-main-swiper-text-font-size: 24px
    --top-main-swiper-text-line-height: 1.6
    --top-main-swiper-text-letter-spacing: 1.6px
    --top-main-swiper-text-margin-top: 5px
  .swiper-slide
    display: flex
    justify-content: center
    align-items: flex-end
    padding-bottom: var(--top-main-swiper-padding-bottom,90px)
    &>figure
      z-index: 1
      &,
      & img
        @extend %overlay
        height: 100%
        width: 100%
      img
        object-fit: cover
      video
        position: absolute
        z-index: -1
        top: 50%
        left: 50%
        object-fit: cover
        transform: translate(-50%, -50%)
        width: 100%
        height: 100%
    &>div
      padding-left: var(--top-main-swiper-padding-x,#{$container-offset})
      padding-right: var(--top-main-swiper-padding-x,#{$container-offset})
      color: var(--top-main-swiper-color,#{$white})
      flex: 1
      position: relative
      z-index: 2
      h2
        font-size: var(--top-main-swiper-hd-font-size,36px)
        line-height: var(--top-main-swiper-hd-line-height,1.13)
        letter-spacing: var(--top-main-swiper-hd-letter-spacing,0.6px)
      p
        margin-top: var(--top-main-swiper-text-margin-top,16px)
        font-size: var(--top-main-swiper-text-font-size,16px)
        line-height: var(--top-main-swiper-text-line-height,1.8)
        letter-spacing: var(--top-main-swiper-text-letter-spacing,0.6px)


.top-intro
  margin-top: 150px
  +mobile
    margin-top: 100px

.top-price
  .columns
    --column-gap: 40px
    --column-flex-basis: 33.33%
    --block-margin-bottom: 20px
    &>*
      display: flex
      &>*
        flex: 1

.top-salon
  &-list
    --column-gap: 40px
    --column-gap-row: 50px
    --column-flex-basis: 50%
    figure
      +block(20px)

.top-facility
  --container-width: 750px
  &-list
    --column-gap: 30px
    --column-gap-row: 40px
    --column-flex-basis: 25%
    +mobile
      --column-gap-row: 70px
      --column-flex-basis: 50%
    figure
      +block(20px)

.side-message
  position: absolute
  top: 0
  left: 0
  width: 53px
  height: auto
  z-index: 2
  +mobile
    width: 35px
    top: 0px
  .about
    &
      +mobile
        top: 65px
  &.is-right
    left: auto
    right: 0
  img
    opacity: 0.6

.viewmore
  &-content
    overflow: hidden
    max-height: 600px
    transition: max-height 1s
    +tablet
      max-height: 1000px
    &.is-active
      max-height: 9999px
      & + .viewmore-button
        display: none
  &-button
    margin-top: 40px

.sp-video
  display: block
  +tablet
    display: none
.pc-video
  display: none
  +tablet
    display: block

.main
  text-align: center
  height: 140vw
  min-height: 524px
  max-height: 80vh
  color: $white
  position: relative
  &-video
    position: absolute
    z-index: -1
    top: 0
    right: 0
    left: 0
    bottom: 0
    overflow: hidden
    video
      position: absolute
      z-index: -1
      top: 50%
      left: 50%
      object-fit: cover
      transform: translate(-50%, -50%)
      width: 100%
      height: 100%
  &-data
    position: absolute
    bottom: 20%
    left: 0
    width: 100%
  svg
    width: 200px
    height: 122px
    display: block
    margin: 0 auto 30px
    +tablet
      width: 224px
      height: 133px
      margin-bottom: 25px
  h2
    font-size: 14px
    letter-spacing: 0.1em
    @extend .is-family-secondary
    font-weight: 500
    border-bottom: 1px solid #fff
    display: inline-block
    padding-bottom: 5px
    +tablet
      font-size: 18px
  h3
    margin-top: 15px
    font-size: 20px
    line-height: 1.2
    letter-spacing: 0.1em
    +tablet
      font-size: 32px

.about
  --container-width: 1130px
  --section-padding-top: 0
  --section-padding-bottom: 0
  --text-lead-font-size: 16px
  --text-lead-line-height: 2
  text-align: left
  .text-lead
    +mobile
      font-size: 14px
  &-figure
    --images-with-text-item-grid-template-columns:1fr #{per($size:360px,$viewport:1130px)}
    --images-with-text-item-gap: 0px #{per($size:100px,$viewport:1130px)}
    .images-with-text-item
      +tablet
        grid-template-areas:"title img" "text img"
      &>.images-with-text-item-figure
        +tablet
          /* padding-top: 30px */
      &>.title-group
        +mobile
          text-align: center
    // &:first-child
    //   .images-with-text-item
    //     &>.images-with-text-item-figure
    //       +mobile
    //         margin-bottom: -20px
    &:nth-child(2n)
      --images-with-text-item-grid-template-columns:1fr #{per($size:465px,$viewport:1130px)}
      .images-with-text-item-figure
        margin-left: calc( -1 * clamp(1.25rem, -11.875rem + 17.5vw, 5.625rem) )
      .images-with-text-item
        +tablet
          grid-template-areas:"img title" "img text"
  &-bottom
    margin-top: 80px
    +tablet
      text-transform: 60px

.about.odd
  --section-padding-top: 0
  --section-padding-bottom: 120px
  .about-figure
    --images-with-text-item-grid-template-columns:#{per($size:360px,$viewport:1130px)} 1fr
    --images-with-text-item-gap: 0px #{per($size:160px,$viewport:1130px)}
    .images-with-text-item-figure
     /*  margin-left: calc( -1 * clamp(1.25rem, -11.875rem + 17.5vw, 5.625rem) ) */
    .images-with-text-item
      +tablet
        grid-template-areas:"img title" "img text"
.value
  &-list
    justify-content: center
    --column-gap: 20px
    +tablet
      --column-gap: 40px
    p
      text-align: left
    figure
      +block(20px)
      img
        border: 1px solid $primary
        border-radius: 100%
        width: 200px
        height: 200px

.case
  &-schedule-list
    justify-content: center
    --column-gap: 20px
    --column-flex-basis: 1
    +tablet
      --column-gap: 40px
    li
      display: flex
      align-items: center
      strong
        display: inline-block
        padding: 2px 10px
        line-height: 1.5
        color: $primary
        border: 1px solid currentColor
        font-weight: normal
        margin-right: 10px
    p
      text-align: left

.message
  --text-lead-line-height: 2

.support
  --container-width: 740px
  .text-lead
    text-align: left
  &-list
    justify-content: center
    --column-gap: 20px
    --column-flex-basis: 50%
    --column-flex-grow: 0
    --column-flex-shrink: 0
    max-width: 580px
    margin-left: auto
    margin-right: auto
    +tablet
      --column-flex-basis: auto
      --column-gap: 26px
      --column-gap-row: 30px
    figure
      +block(10px)
      img
        border: 1px solid $primary
        border-radius: 100%
        width: 152px
        height: auto
    h3
      @extend .is-family-secondary

.flow
  &-step
    display: flex
    flex-direction: column
    gap: 20px
    @extend .is-family-secondary
    font-weight: 500
    &>*
      background: $white
      text-align: center
      padding: 10px
      list-style: none

.gmap
  height: 450px
  display: block
  position: relative
  margin: 0
  filter: grayscale(100%)
  iframe
    bottom: 0
    left: 0
    position: absolute
    right: 0
    top: 0
    height: 100%
    width: 100%
    filter: grayscale(100%)

.system
  &-list
    display: flex
    flex-direction: column
    gap: 90px
    &-item
      --column-gap: 40px
      .columns
        text-align: left
        align-items: center
        +tablet
          &>:nth-child(1)
            max-width: 38%
            flex-basis: 38%
          &>:nth-child(2)
            flex: 1
      &:nth-child(2n)
        .columns
          +tablet
            flex-direction: row-reverse