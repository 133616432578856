$tag-background-color: $background !default
$tag-color: $text !default
$tag-radius: $radius !default
$tag-delete-margin: 1px !default

$tag-colors: $colors !default

%tag-primary
  background: $primary
  border-color: $primary
  color: $white

.tags
  align-items: center
  display: flex
  flex-wrap: wrap
  justify-content: flex-start
  gap: 5px
  // Sizes
  // &.are-medium
  //   .tag:not(.is-normal):not(.is-large)
  //     font-size: $size-normal
  // &.are-large
  //   .tag:not(.is-normal):not(.is-medium)
  //     font-size: $size-medium
  &.is-centered
    justify-content: center
  // &.is-right
  //   justify-content: flex-end
  //   .tag
  //     &:not(:first-child)
  //       margin-left: 0.5rem
  //     &:not(:last-child)
  //       margin-right: 0
  // &.has-addons
  //   .tag
  //     +ltr-property("margin", 0)
  //     &:not(:first-child)
  //       +ltr-property("margin", 0, false)
  //       +ltr
  //         border-top-left-radius: 0
  //         border-bottom-left-radius: 0
  //       +rtl
  //         border-top-right-radius: 0
  //         border-bottom-right-radius: 0
  //     &:not(:last-child)
  //       +ltr
  //         border-top-right-radius: 0
  //         border-bottom-right-radius: 0
  //       +rtl
  //         border-top-left-radius: 0
  //         border-bottom-left-radius: 0

.tag:not(body)
  align-items: center
  background-color: $tag-background-color
  border-radius: $tag-radius
  color: $tag-color
  display: inline-flex
  font-size: $tag-font-size
  height: 24px
  justify-content: center
  letter-spacing: 0
  line-height: 1.5
  padding-left: 20px
  padding-right: 20px
  white-space: nowrap
  border: $tag-border
  .delete
    +ltr-property("margin", 0.25rem, false)
    +ltr-property("margin", -0.375rem)
  // Sizes
  // &.is-normal
  //   font-size: $size-small
  // &.is-medium
  //   font-size: $size-normal
  // &.is-large
  //   font-size: $size-medium
  // .icon
  //   &:first-child:not(:last-child)
  //     +ltr-property("margin", -0.375em, false)
  //     +ltr-property("margin", 0.1875em)
  //   &:last-child:not(:first-child)
  //     +ltr-property("margin", 0.1875em, false)
  //     +ltr-property("margin", -0.375em)
  //   &:first-child:last-child
  //     +ltr-property("margin", -0.375em, false)
  //     +ltr-property("margin", -0.375em)
  // Modifiers
  // &.is-delete
  //   +ltr-property("margin", $tag-delete-margin, false)
  //   padding: 0
  //   position: relative
  //   width: 2em
  //   &::before,
  //   &::after
  //     background-color: currentColor
  //     content: ""
  //     display: block
  //     left: 50%
  //     position: absolute
  //     top: 50%
  //     transform: translateX(-50%) translateY(-50%) rotate(45deg)
  //     transform-origin: center center
  //   &::before
  //     height: 1px
  //     width: 50%
  //   &::after
  //     height: 50%
  //     width: 1px
  //   &:hover,
  //   &:focus
  //     background-color: darken($tag-background-color, 5%)
  //   &:active
  //     background-color: darken($tag-background-color, 10%)
  // &.is-rounded
  //   border-radius: $radius-rounded
  &.is-link
    border-radius: 0
  &.is-primary
    @extend %tag-primary

a.tag
  &:hover
    @extend %tag-primary
