
.collapse
  --icon-arrow-size: 10px
  --icon-arrow-top: calc(50% - 7px)
  text-align: left
  border-bottom: 1px solid #D2C6B7
  padding-bottom: 20px
  +block(20px)
  &-trigger
    position: relative
    cursor: pointer
    font-family: $family-secondary
    padding-left: 30px
    padding-right: 25px
    font-size: 16px
    @extend a
    &::before
      font-family: $family-third
      content: "Q"
      display: block
      font-size: 18px
      position: absolute
      top: 0.2em
      left: 0
      line-height: 1
    &::after
      transition: all 0.08s linear
      content: ""
      display: block
      position: absolute
      right: 0
      transition: all linear .05s
      width: var(--icon-arrow-size)
      height: var(--icon-arrow-size)
      border: 1px solid currentColor
      border-width: 1px 1px 0 0
      top: var(--icon-arrow-top)
      transform: rotate(135deg)
      right: 1px
  &-content
    padding-top: 10px
    font-size: 14px

  // modifier
  &.is-active
    --icon-arrow-top: calc(50%)
    .collapse-trigger
      &::after
        transform: rotate(-45deg)
