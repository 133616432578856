@import "../utilities/mixins"
/* lead */
%text-lead_is-medium
  --text-lead-line-height:2.57
  --text-lead-letter-spacing: 0.7px
.text-lead
  font-size: var(--text-lead-font-size,14px)
  line-height: var(--text-lead-line-height,2.29)
  letter-spacing: var(--text-lead-letter-spacing,inherit)
  +block(60px)
  p
    +block(2em)
  &.is-medium
    --text-lead-font-size: 18px
    --text-lead-line-height: 2
    font-weight: 500
    p
      +block(1.5em)
  &.is-medium-02
    --text-lead-font-size: 16px
    --text-lead-line-height: 2
    p
      +block(1.5em)

/* marker */
.text-marker
  display: inline-block
  position: relative
  background: linear-gradient(#a2a09c, #a2a09c)
  background-size: 100% 8px
  background-position: left calc(100% - 2px)
  background-repeat: repeat-x

.link-text
  text-decoration: underline
  &:hover
    text-decoration: none
  &.is-map
    &::before
      content: ""
      display: inline-block
      vertical-align: middle
      margin-right: 5px
      width: 10px
      height: 14px
      background: 0 center no-repeat url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22none%22%20viewBox%3D%220%200%2010%2015%22%3E%3Cg%20fill%3D%22%23231815%22%20clip-path%3D%22url(%23a)%22%3E%3Cpath%20d%3D%22m5%2014.52-.23-.3C4.57%2013.98%200%208.14%200%205a5%205%200%200%201%2010%200c0%203.14-4.57%208.98-4.77%209.22l-.23.3ZM5%20.6A4.41%204.41%200%200%200%20.6%205c0%202.55%203.46%207.3%204.4%208.55.94-1.25%204.4-6%204.4-8.55C9.4%202.46%207.43.6%205%20.6Z%22%2F%3E%3Cpath%20d%3D%22M5%206.83a2.24%202.24%200%201%201%200-4.48%202.24%202.24%200%200%201%200%204.48Zm0-3.88a1.65%201.65%200%201%200%200%203.29%201.65%201.65%200%200%200%200-3.3Z%22%2F%3E%3C%2Fg%3E%3Cdefs%3E%3CclipPath%20id%3D%22a%22%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M0%200h10v14.52H0z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3C%2Fsvg%3E')
.link-line
  margin-top: 20px
/* note */
.text-note
  color: #666666
  font-size: 12px
  position: relative
  padding-left: 1.2em
  &>span
    &:first-child
      position: absolute
      top: 0.1em
      left: 0

/* color */
.text-color-danger
  color: #D50783 !important