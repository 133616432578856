$speed-slow: 150ms;
$speed-slower: 250ms;

// colors
// --------------------------------------------
$black: #000000;
$text: $black;
$primary: #AC9376;
$white: #ffffff;
$white-bis: #EAE5E0;
$white-ter: #dddddd;
$grey: #484848;
$grey-light: #999999;

$background-01:$white-bis;
$background-02:#d0ceca;

// font
// --------------------------------------------
// font-size

$size-small: 12px;
$size-normal: 14px;
$size-medium: 16px;
$size-large: 18px;

$sizes-custom: 11,12, 13, 14, 15, 16, 18, 20, 24;

// dimensions
// --------------------------------------------
$dimensions: 16;

$spacing-values: (
  "auto": auto,
  "0": 0px,
  "10": 10px,
  "20": 20px,
  "30": 30px,
  "40": 40px,
  "60": 60px
);

$border: $grey;

// The container horizontal gap, which acts as the offset for breakpoints
$gap: 15px;
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px;
// 960px container + 4rem
$desktop: 1030px + (2 * $gap);
// 1152px container + 4rem
$widescreen: 1366px;
$widescreen-enabled: false;
// 1344px container + 4rem
$fullhd: 1024px + (2 * $gap);
$fullhd-enabled: false;

// Typography
$weight-medium: 500;
$family-sans-serif: "Zen Kaku Gothic New", sans-serif;;
$family-primary: $family-sans-serif;
$family-secondary: "Noto Serif JP", sans-serif;
$family-third: 'Larken', serif;

// body
$body-background-color: $white-bis;
$body-family: $family-sans-serif;
$body-size: 14px;
$body-color: $black;
$body-line-height: 1.75;

// container
$container-offset: clamp(20px,4.2vw,40px);

// hr
$hr-background-color: #ddd;
$hr-height: 1px;
$hr-margin: 25px 0;

// text
$text: $black;

// link
$link: var(--link-color,#{$text});
$link-hover: var(--link-hover-color,#666);

//control
$control-height: 50px;
$control-height-mobile: 36px;
$control-padding-horizontal: 20px;

$radius: 4px;

// coumn
$column-gap: 10px;

// block
$block-spacing: 20px;

// navbar
$navbar-breakpoint: $tablet;
$navbar-item-hover-color: $black;

// setion
$section-padding: 30px 0 80px;
$section-padding-medium: 40px 0 60px;
$section-padding-medium-tablet: 80px 0 130px;
$section-padding-small: 20px 0;

// box
$box-color: $black;
$box-background-color: #F2EEEB;
$box-radius: 0;
$box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
$box-padding: 30px;

// $box-link-hover-shadow: 0 0.5em 1em -0.125em rgba($scheme-invert, 0.1), 0 0 0 1px $link;
// $box-link-active-shadow: inset 0 1px 2px rgba($scheme-invert, 0.2), 0 0 0 1px $link;

// breadcrumb
$breadcrumb-item-separator-color: $black;

//title
$title-color: $text;
$title-size: 12px;
// $title-line-height: 1.25;
$title-weight: bold;

// $subtitle-color: $primary;
$subtitle-weight: $title-weight;
// $subtitle-negative-margin: -35px;
// $subtitle-size:20px;

// button
$button-background-color: $grey;
$button-border-color: $grey;
$button-border-width: 0;
$button-hover-color: $white;

$button-text-color: $white;

$button-disabled-background-color: $grey;
$button-disabled-border-color: $grey;
$button-disabled-shadow: none;
$button-disabled-opacity: 0.5;

//pagination
// $pagination-color: $text;
// $pagination-border-color: none;
// $pagination-margin: -0.25rem;
// $pagination-min-width: $control-height;

// $pagination-item-font-size: 16px;
// $pagination-item-margin: 0px;
// $pagination-item-padding-left: 20px;
// $pagination-item-padding-right: 20px;

// $pagination-hover-color: $link-hover;
// $pagination-hover-border-color:none;

// $pagination-focus-color: $link-focus;
// $pagination-focus-border-color: $link-focus-border;

// $pagination-active-color: $link-active;
// $pagination-active-border-color: $link-active-border;

// $pagination-disabled-color: $text-light;
// $pagination-disabled-background-color: $border;
// $pagination-disabled-border-color: $border;

// $pagination-current-color: $link-invert;
// $pagination-current-background-color: $link;
// $pagination-current-border-color: $link;

// $pagination-ellipsis-color: $grey-light;

// $pagination-shadow-inset: inset 0 1px 2px rgba($scheme-invert, 0.2);

// content
$content-heading-color: $text;
$content-heading-line-height: 1.75;

// tools
$label-color: $black;
$label-weight: normal;

// input
// $input-color: $black;
// $input-background-color: $white-ter;
// $input-border-color: $primary-light;
// $input-height: $control-height;
// $input-shadow: none;
// $input-placeholder-color: $grey-light;

// $input-hover-color: $input-color;
// $input-hover-border-color: $input-border-color;

// $input-focus-color: $black;
// $input-focus-border-color: $primary;
// $input-focus-background-color: $white;
// $input-focus-box-shadow-size: none;
// $input-focus-box-shadow-color: none;

// $input-disabled-color: $text-light;
// $input-disabled-background-color: $background;
// $input-disabled-border-color: $background;
// $input-disabled-placeholder-color: bulmaRgba($input-disabled-color, 0.3);

$input-arrow: $primary-light;

$input-icon-color: $primary-light;
$input-icon-active-color: $primary;

$input-radius: $radius;

$textarea-padding: 15px 20px;

// help
$help-size: 13px;

//tag
$tag-radius: 24px;
$tag-font-size: 13px;
$tag-background-color: $white;
$tag-color: $primary;
$tag-border: 1px solid $primary;

// table
$table-color: $text;
$table-cell-padding: 5px 10px;

// modal
$modal-z: 900;
$modal-close-dimensions: 56px;
$modal-close-right: 0px;
$modal-close-top: 0px;
$modal-background-background-color: bulmaRgba($black, 0.4);
$modal-card-body-background-color: #F2EEEB;
$modal-card-body-padding: 60px;

// tab
// $tabs-link-color: $primary;
// $tabs-link-hover-border-bottom-color: $text-strong;
// $tabs-link-hover-color: $text-strong;
// $tabs-link-active-border-bottom-color: $link;
// $tabs-link-active-color: $link;
// $tabs-link-padding: 0.5em 1em;
// $tabs-toggle-link-border-color: $primary-lighter;
// $tabs-toggle-link-border-style: solid;
// $tabs-toggle-link-border-width: 1px;
// $tabs-toggle-link-hover-background-color: $primary;
// $tabs-toggle-link-hover-border-color: $primary;
// $tabs-toggle-link-radius: none;
// $tabs-toggle-link-active-background-color: $primary;
// $tabs-toggle-link-active-border-color: $primary;
// $tabs-toggle-link-active-color: $white;

// footer
$footer-background-color: $white;

// header
$header-height: 55px;
