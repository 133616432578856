/* sns */
.list-sns
  --column-gap:20px
  --column-flex-grow:0
  justify-content: center
  align-items: center
  line-height: 1
  @extend .columns
  @extend .is-family-secondary
  .icon
    height: var(--list-sns-icon-height,30px)
    display: flex
    justify-content: center
    align-items: center
    +block(11px)

  &.is-large
    --column-gap: 45px
    --list-sns-icon-height: 60px
    .icon-font-mail
      font-size: 75px
    .icon-font-line,
    .icon-font-instagram
      font-size: var(--list-sns-icon-height)
    +tablet
      --list-sns-gap: 85px