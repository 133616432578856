body
  padding-top: 160px
  +mobile
    padding-top: 140px

.header
  position: fixed
  top: 0
  left: 0
  width: 100%
  z-index: 910
  color: $white
  font-family: $family-third
  letter-spacing: 0.1em
  font-size: 16px
  &-inner
    padding-top: 40px
    padding-bottom: 40px
    +mobile
      padding-top: 30px
      padding-bottom: 30px
      padding-right: 30px
  .navbar-burger
    &.is-active
      color: $primary
  .navbar-menu
    a:not(.button)
      color: $primary
      &:hover
        color: $black
  &.is-white
    a
      color: $white
  &.is-black
    a
      color: $black

.header-sitelogo
  text-align: left
  svg
    width: 120px
    height: 57px
    vertical-align: bottom
    +mobile
      width: 80px
      height: auto

.header-mobile
  justify-content: flex-start
  padding: 170px 40px 100px
  a:not(.button)
    color: $primary
  .modal-content
    margin: 0 0 auto 0
    width: 100%
  &-nav
    display: flex
    flex-direction: column
    gap: 40px
    font-size: 20px

#salon-view
  z-index: 999
  .modal-card
    +mobile
      max-height: calc(100vh - #{$modal-content-spacing-tablet})
  .modal-content
    +mobile
      max-width: calc(100% - 40px)
      margin-bottom: auto
      margin-top: 20px